// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useLocation } from 'react-router-dom';
// import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder, NodePOSTApiAuth } from '../../../Constant/api';
// import { slugs } from '../../../Constant/slugs';
// import { logout } from '../../../redux/action';
// import NotFound from '../../NotFound';

// const slug = {
//   "ubt": "UBT",
//   "cbt-chemical": "CBT CHEMICAL",
//   "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
//   "cbt-food-related": "CBT FOOD RELATED",
//   "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
//   "cbt-metal": "CBT METAL",
//   "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
//   "cbt-rubber": "CBT RUBBER",
//   "cbt-textile-sewing": "CBT TEXTILE SEWING"
// }
// export default function Chapters(props) {
//   const { ChapterData } = props;
//   const dispatch = useDispatch()
//   const LoginState = useSelector((state) => state.LoginState);


//   return (
//     <div style={{ margin: "3px" }}>
//       <div className="row">
//         <div className="col-1"></div>
//         <div className="col-10 form-view mt-3">
//           <div className="row" style={{ margin: "10px" }}>
//             <h2>Chapters</h2>
//             <div className="row" style={{ marginLeft: "10px" }}>
//               {ChapterData.map((item, index) => (
//                 <div key={index} className="col-sm-4 col-12">
//                   <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
//                   // onClick={() => {
//                   //   setPreviewPressed(true)
//                   //   setPreviewObj(item)
//                   //   setPreviewType(item.chapter_type)
//                   //   if (item.chapter_type == "Video") {
//                   //     if (item.youtube_id == "")
//                   //       setPreviewUrl(item.video_link)
//                   //     else
//                   //       setPreviewUrl(`https://www.youtube.com/embed/${item.youtube_id}`)
//                   //   }
//                   //   else if (item.chapter_type == "PDF")
//                   //     setPreviewUrl(item.pdf_url)
//                   //   else
//                   //     setPreviewUrl(item.content)

//                   // }}
//                   >
//                     {/* {JSON.stringify(item)} */}

//                     {/* <h4>{item.chapter_number} {item.title}</h4>
//                     <div style={{ marginLeft: "40px", fontSize: "12px" }}>
//                       <span>[{item.time}]</span>
//                       <span>[{item.chapter_type}]</span>
//                     </div> */}
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div >
//   )
// }



import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder, NodePOSTApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';
import NotFound from '../../NotFound';
import ReactPlayer from 'react-player'
import axios from 'axios';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function Chapters(props) {
  const { ChapterData } = props;
  const dispatch = useDispatch()
  const LoginState = useSelector((state) => state.LoginState);
  const [classDetails, setClassDetails] = React.useState({})
  const [choose, setChoose] = React.useState(0)
  const [PreviewUrl, setPreviewUrl] = React.useState("")
  const [Youtube, setYoutube] = React.useState(false)
  const [PreviewPressed, setPreviewPressed] = React.useState(false)
  const [PreviewType, setPreviewType] = React.useState("")
  const [PdfURL, setPdfURL] = React.useState("")

  useEffect(() => {
    function ctrlShiftKey(e, keyCode) {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        // event.keyCode === 123 ||
        ctrlShiftKey(e, 'I') ||
        ctrlShiftKey(e, 'J') ||
        ctrlShiftKey(e, 'C') ||
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
      )
        return false;
    };
  }, [])

  const downloadImage = async (imageurl, filename) => {
    // const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    // const imageUrl = "https://api.oppaonnihakwon.com/wp-content/uploads/2025/01/BUTAB.jpeg";
    var nodejsurl = "https://real-exam.epsexam.com/base64"
    axios({ method: "GET", url: `${nodejsurl}?url=${imageurl}` })
      .then(res => {
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${res.data.data}`;
        link.download = filename;
        // setBase64(`data:image/jpeg;base64,${res.data.data}`)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
  };
  return (
    <div style={{ margin: "3px" }}>
      {choose == 0 &&
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 form-view mt-3">
            <div className="row" style={{ margin: "10px" }}>
              <h2>Classes</h2>
              <div className="row" style={{ marginLeft: "10px" }}>
                {ChapterData.map((item, index) => (
                  <div key={index} className="col-sm-6 col-12">
                    <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}

                    >
                      <h4>{item.chapter_number} {item.title.rendered}</h4>
                      <div className="row">
                        <div className="col-sm-4" style={{ padding: 10 }}
                          onClick={() => {
                            setClassDetails(item)
                            setChoose(1)
                          }}
                        >
                          <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}> View Class</span>
                        </div>
                        {item.meta.class_link !== "" &&
                          <div className="col-sm-4" style={{ padding: 10 }}>
                            <a className="Link" href={item.meta.class_link} target="_blank">
                              <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}> Join Live</span>
                            </a>
                          </div>
                        }
                        {item.meta.whatsapp_group !== "" &&
                          <div className="col-sm-4" style={{ padding: 10 }}>
                            <a className="Link" href={item.meta.whatsapp_group} target="_blank">
                              <span style={{ border: "1px solid black", borderRadius: 5, padding: "5px 15px" }}>Whatsapp</span>
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      }
      {choose == 1 &&
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 form-view mt-3">
            <div className="row" style={{ margin: "10px" }}>
              <h2>{classDetails.title.rendered}</h2>
              <span style={{ textAlign: "right", cursor: "pointer" }}
                onClick={() => {
                  setChoose(0)
                }}
              >
                All Class
              </span>
              <div className="row" style={{ marginLeft: "10px" }}>
                {Object.values(JSON.parse(classDetails.meta.course_content)).map((item2, index2) => (
                  // {ChapterData.map((item, index) => (
                  <div key={index2} className="col-md-4 col-12">
                    <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}>
                      <h4>{item2.chapter_number}. {item2.title}</h4>
                      <div>{item2.description}</div>

                      <div style={{ padding: 0, display: "inline" }}
                        onClick={() => {
                          if (item2.youtube_id == "")
                            setPreviewUrl(item2.video_link)
                          else {
                            // const url = `https://youtubemp4link.nextgendesigner.com/mp4_link?url=https://www.youtube.com/watch?v=${item2.youtube_id}`
                            // axios(url)
                            const url = `https://youtubemp4link.nextgendesigner.com/mp4_link_p`
                            axios({
                              method: "GET",
                              url: url,
                              params: {
                                video_url: `https://www.youtube.com/watch?v=${item2.youtube_id}`
                              },
                              timeout: 5000
                            })
                              .then(res => {
                                console.log(res.data)
                                setPreviewPressed(true)
                                // setPreviewUrl(res.data)
                                if (res.data.includes("ERROR")) {
                                  setPreviewUrl(`https://www.youtube.com/embed/${item2.youtube_id}?rel=0`)
                                  setYoutube(true)
                                }
                                else {
                                  setPreviewUrl(res.data)
                                  setYoutube(false)
                                }
                              })
                          }
                        }}
                      >
                        <span style={{ border: "1px solid black", borderRadius: 5, padding: "3px 10px",fontSize:"12px" }}> Video</span>
                      </div>
                      <div style={{ padding: 0, display: "inline" }}>
                        {Object.values(JSON.parse(item2.pdf_list)).map((item4, index4) => (
                          <div key={index4} style={{ display: "inline-block", margin: 3 }}>
                            <span style={{ border: "1px solid black", borderRadius: 5, padding: "3px 10px",fontSize:"12px" }}
                              onClick={() => {
                                setPreviewType("PDF")
                                setPdfURL(item4)
                              }}
                            >
                              View PDF
                              {Object.values(JSON.parse(item2.pdf_list)).length == 1 ? "" : index4 + 1}
                              {/* {item4.split("/")[item4.split("/").length - 1].replace(".pdf","")} */}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div style={{ padding: 0, display: "inline" }}>
                        {Object.values(JSON.parse(item2.pdf_list)).map((item4, index4) => (
                          <div key={index4} style={{ display: "inline-block", margin: 3 }}>
                            <span style={{ border: "1px solid black",marginTop:5, borderRadius: 5, padding: "3px 10px",fontSize:"12px" }}
                              onClick={() => {
                                // setPreviewType("PDF")
                                // setPdfURL(item4)
                                downloadImage(item4,`${item4.split("/")[item4.split("/").length - 1].replace(".pdf","")}.pdf`)
                              }}
                            >
                              Download PDF
                              {Object.values(JSON.parse(item2.pdf_list)).length == 1 ? "" : index4 + 1}
                              {/* {item4.split("/")[item4.split("/").length - 1].replace(".pdf","")} */}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div style={{ marginLeft: "40px", fontSize: "12px" }}>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      }
      {PreviewPressed &&
        <div className="modal-2" style={{ userSelect: "none" }}>
          <div className="row" style={{ width: "100%" }}>
            <div data-dismiss="modal" aria-label="Close"
              onClick={() => { setPreviewPressed(false) }}
            >
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div class="modal-body">
            <div style={{ minHeight: "78vh", overflowY: "scroll" }}>
              {/* <video
                style={{ width: "100%", height: "70vh" }}
                controls autoplay controlsList="nodownload" oncontextmenu="return false;">
                <source src={PreviewUrl} type="video/mp4" />
              </video> */}
              {Youtube ?
                <div style={{ position: "relative" }}>
                  {/* <div style={{ position: "absolute", backgroundColor: "#00000022", height: 100, width: "100%" }} /> */}
                  {/* <div style={{ position: "relative", width: "100%", height: "100%" }}> */}
                  {/* <div style={{ position: "absolute", bottom: 10, backgroundColor: "#00000099", height: 50, width: 200, justifyContent: "center", }} /> */}
                  {/* <div style={{ position: "absolute", right: 0, bottom: 10, backgroundColor: "#00000099", height: 50, width: 120, justifyContent: "center", }} /> */}
                  {/* </div> */}
                  <iframe
                    style={{ width: "100%", height: "100vh" }}
                    src={PreviewUrl
                    }>
                  </iframe>
                </div>
                :
                <video
                  style={{ width: "100%", height: "70vh" }}
                  controls autoplay controlsList="nodownload" oncontextmenu="return false;">
                  <source src={PreviewUrl} type="video/mp4" />
                </video>
              }
            </div>
          </div>
        </div>
      }

      {PreviewType == "PDF" &&
        // <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">

        <div class="modal-2" style={{
          userSelect: "none", marginTop: 0, top: 0
        }}>
          <div style={{ width: "100vw" }}
            onClick={() => { setPreviewType("") }}
          >
            <div
              data-dismiss="modal" aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </div>
          </div>
          <div>
            <div>
              <div className='d-sm-block d-none '>
                <iframe style={{ width: "100%", height: "95vh", position: "absolute", zIndex: 1 }}
                  src={`${PdfURL}`} />
              </div>
              <div className='d-block d-sm-none '>
                <iframe style={{ width: "100%", height: "95vh", position: "absolute", zIndex: 1 }}
                  src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${PdfURL}`}
                />

              </div>
              <div >
              </div>
              <div style={{ padding: "37vh 50vw", backgroundColor: "#eee" }}>
                <div className="dot-pulse-2"></div>
              </div>
            </div>
          </div>
        </div>
      }

    </div >
  )
}

